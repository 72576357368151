<template>
  <div>
    <b-modal
      id="modal-edit-capem"
      size="lg"
      centered
      title="Edit Data Capem"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Nama Capem" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaCapem')"
            v-model="$v.dataEdit.namaCapem.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Kode Capem" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('kodeCapem')"
            v-model="$v.dataEdit.kodeCapem.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.mitraIdEdit.$model"
            :state="checkIfValid('mitraIdEdit')"
            :options="mitra"
            @input="getKoordinators(dataEdit.mitraIdEdit)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Koordinator" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.koordinatorIdEdit.$model"
            :state="checkIfValid('koordinatorIdEdit')"
            :options="koordinator"
            @input="getCabangs(dataEdit.koordinatorIdEdit)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKoordinator"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Cabang" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.cabangIdEdit.$model"
            :state="checkIfValid('cabangIdEdit')"
            :options="cabang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCabang"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Provinsi" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.provinsiIdEdit.$model"
            :state="checkIfValid('provinsiIdEdit')"
            :options="provinsi"
            @input="getKotas(dataEdit.provinsiIdEdit)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            label="namaProvinsi"
            track-by="id"
            placeholder="-- Pilih Provinsi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kota" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.kotaIdEdit.$model"
            :state="checkIfValid('kotaIdEdit')"
            :options="kota"
            @input="getKecamatans(dataEdit.kotaIdEdit)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKota"
            track-by="idKota"
            placeholder="-- Pilih Kabupaten/Kota --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kecamatan" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.kecamatanIdEdit.$model"
            :state="checkIfValid('kecamatanIdEdit')"
            :options="kec"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKecamatan"
            track-by="idKecamatan"
            placeholder="-- Pilih Kecamatan --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('alamatCapem')"
            v-model="$v.dataEdit.alamatCapem.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="No. Telp" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('noHpKantor')"
            v-model="$v.dataEdit.noHpKantor.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Latitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('latitude')"
            v-model="$v.dataEdit.latitude.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Longtitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('longitude')"
            v-model="$v.dataEdit.longitude.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-capem')"
        >
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: ["data", "mitra", "provinsi", "edit"],
  data() {
    return {
      dataEdit: {
        mitraIdEdit: "",
        koordinatorIdEdit: "",
        provinsiIdEdit: "",
        kotaIdEdit: "",
        cabangIdEdit: "",
        kecamatanIdEdit: "",
        namaCapem: "",
        kodeCapem: "",
        alamatCapem: "",
        noHpKantor: "",
        longitude: "",
        latitude: "",
      },
      kota: [],
      kec: [],
      koordinator: [],
      cabang: [],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataEdit, null, 4);
    },
    isValid() {
      return !this.$v.dataEdit.$invalid;
    },
    isDirty() {
      return this.$v.dataEdit.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataEdit: {
      mitraIdEdit: {
        required,
      },
      cabangIdEdit: {
        required,
      },
      koordinatorIdEdit: {
        required,
      },
      provinsiIdEdit: {
        required,
      },
      kotaIdEdit: {
        required,
      },
      kecamatanIdEdit: {
        required,
      },
      namaCapem: {
        required,
      },
      kodeCapem: {
        required,
      },
      alamatCapem: {
        required,
      },
      noHpKantor: {
        required,
      },
      longitude: {
        required,
      },
      latitude: {
        required,
      },
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        for (let i = 0; i < this.provinsi.length; i++) {
          if (this.provinsi[i].id == this.data.provinsiId) {
            this.dataEdit.provinsiIdEdit = this.provinsi[i];
          }
        }
        for (let i = 0; i < this.mitra.length; i++) {
          if (this.mitra[i].id == this.data.mitraId) {
            this.dataEdit.mitraIdEdit = this.mitra[i];
          }
        }
        this.dataEdit.namaPusat = this.data.namaPusat;
        this.dataEdit.namaCapem = this.data.namaCapem;
        this.dataEdit.kodeCapem = this.data.kodeCapem;
        this.dataEdit.alamatCapem = this.data.alamatCapem;
        this.dataEdit.noHpKantor = this.data.noHpKantor;
        this.dataEdit.longitude = this.data.longitude;
        this.dataEdit.latitude = this.data.latitude;
        this.getKota(this.dataEdit.provinsiIdEdit);
        this.getKoordinator(this.dataEdit.mitraIdEdit);
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataEdit[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      console.log(x);
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      // console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
      for (let i = 0; i < this.koordinator.length; i++) {
        if (this.koordinator[i].koordinatorId == this.data.koordinatorId) {
          this.dataEdit.koordinatorIdEdit = this.koordinator[i];
        }
      }
      this.getCabang(this.dataEdit.koordinatorIdEdit);
      //   console.log(this.data.kotaIdEdit, "kota edit");
    },
    async getKoordinators(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      // console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
      this.dataEdit.koordinatorIdEdit = "";
      this.dataEdit.cabangIdEdit = "";
      //   console.log(this.data.kotaIdEdit, "kota edit");
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      // console.log(cabang.data.data, "ini koordinator");
      this.cabang = cabang.data.data;
      for (let i = 0; i < this.cabang.length; i++) {
        if (this.cabang[i].cabangId == this.data.cabangId) {
          this.dataEdit.cabangIdEdit = this.cabang[i];
        }
      }
      //   console.log(this.data.kotaIdEdit, "kota edit");
    },
    async getCabangs(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      // console.log(cabang.data.data, "ini koordinator");
      this.cabang = cabang.data.data;
      this.dataEdit.cabangIdEdit = "";
    },
    async getKota(x) {
      let kota = await axios.get(ipBackend + "kota/listByProvinsiId/" + x.id);
      // console.log(kota.data.data, "ini kota");
      this.kota = kota.data.data;
      for (let i = 0; i < this.kota.length; i++) {
        if (this.kota[i].idKota == this.data.kotaId) {
          this.dataEdit.kotaIdEdit = this.kota[i];
        }
      }
      // console.log(this.data.kotaIdEdit, "kota edit");
      this.getKecamatan(this.dataEdit.kotaIdEdit);
    },
    async getKotas(x) {
      let kota = await axios.get(ipBackend + "kota/listByProvinsiId/" + x.id);
      // console.log(kota.data.data, "ini kota");
      this.kota = kota.data.data;
      this.dataEdit.kotaIdEdit = "";
      this.dataEdit.kecamatanIdEdit = "";
    },
    async getKecamatan(x) {
      let kec = await axios.get(
        ipBackend + "kecamatan/listByKotaId/" + x.idKota
      );
      // console.log(kec.data.data, "ini kecamatan");
      this.kec = kec.data.data;
      for (let i = 0; i < this.kec.length; i++) {
        if (this.kec[i].idKecamatan == this.data.kecamatanId) {
          this.dataEdit.kecamatanIdEdit = this.kec[i];
        }
      }
    },
    async getKecamatans(x) {
      let kec = await axios.get(
        ipBackend + "kecamatan/listByKotaId/" + x.idKota
      );
      // console.log(kec.data.data, "ini kecamatan");
      this.kec = kec.data.data;
      this.dataEdit.kecamatanIdEdit = "";
    },
    simpan() {
      let vm = this;
      vm.data.provinsiId = vm.dataEdit.provinsiIdEdit.id;
      vm.data.mitraId = vm.dataEdit.mitraIdEdit.id;
      vm.data.koordinatorId = vm.dataEdit.koordinatorIdEdit.id;
      vm.data.cabangId = vm.dataEdit.cabangIdEdit.id;
      vm.data.kotaId = vm.dataEdit.kotaIdEdit.idKota;
      vm.data.kecamatanId = vm.dataEdit.kecamatanIdEdit.idKecamatan;
      vm.data.id = vm.data.capemId;
      vm.data.namaCapem = vm.dataEdit.namaCapem;
      vm.data.kodeCapem = vm.dataEdit.kodeCapem;
      vm.data.alamatCapem = vm.dataEdit.alamatCapem;
      vm.data.noHpKantor = vm.dataEdit.noHpKantor;
      vm.data.longitude = vm.dataEdit.longitude;
      vm.data.latitude = vm.dataEdit.latitude;
      axios
        .post(ipBackend + "capem/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH DATA CAPEM",
              showing: true,
            });
            this.$bvModal.hide("modal-edit-capem");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
