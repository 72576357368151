<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-button
                variant="primary"
                @click="getDatas()"
                v-b-modal.modal-input-cabang
                ><CIcon name="cil-plus" /> Tambah Data</b-button
              >
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <hr />
            </b-col>
          </b-row>

          <b-alert :show="showing" :variant="variant" dismissible fade>{{
            msg
          }}</b-alert>

          <b-row>
            <b-col md="3">
              <b-form-group
                label="Per Halaman"
                label-for="per-page-select"
                label-cols-md="6"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="md"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" offset-md="3">
              <b-form-group
                label="Cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model.lazy="filter"
                    type="search"
                    placeholder="Ketik disini untuk mencari ..."
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >Hapus</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
                :busy="tableBusy"
              >
                <template #cell(actions)="item">
                  <b-button
                    variant="warning"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Edit Data'"
                    v-b-modal.modal-edit-cabang
                    @click="(data = item.item), (edit = !edit)"
                    ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                  >

                  <b-button
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Hapus Data'"
                    v-b-modal.modal-delete-cabang
                    @click="data = item.item"
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  >
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5" offset-md="7">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <modalInput
      @alertFromChild="triggerAlert($event)"
      :mitra="mitra"
      :provinsi="provinsi"
    />
    <modalEdit
      :data="data"
      :mitra="mitra"
      :provinsi="provinsi"
      :edit="edit"
      @alertFromChild="triggerAlert($event)"
    />
    <modalDelete @alertFromChild="triggerAlert($event)" :data="data" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalInput from "./modalInput.vue";
import modalEdit from "./modalEdit.vue";
import modalDelete from "./modalDelete.vue";

export default {
  components: {
    modalInput,
    modalEdit,
    modalDelete,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      mitra: [],
      provinsi: [],
      edit: false,
      items: [
        {
          no: "-",
          pusat: "-",
          mitra: "-",
          alamat: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "kodeCabang",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "namaCabang",
          label: "Cabang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "alamatCabang",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "noHpKantor",
          label: "No. Telp",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "namaKoordinator",
          label: "Koordinator",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "namaMitra",
          label: "Mitra",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
    this.getProvinsi();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      this.tableBusy = true;
      let listCabang = await axios.get(ipBackend + "cabang/list");
      this.items = listCabang.data.data;

      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.mitra = listMitra.data.data;

      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;
      }
      //   console.log(this.items, "ini cabang");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    async getProvinsi() {
      let vm = this;
      let provinsi = await axios.get(ipBackend + "provinsi/list");
      vm.provinsi = provinsi.data.data.map((item) => {
        item.namaProvinsi = _.upperFirst(item.namaProvinsi);
        return item;
      });
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>
