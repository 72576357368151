<template>
  <div>
    <b-modal
      id="modal-input-mitra"
      size="lg"
      centered
      title="Tambah Data Mitra"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Nama Mitra" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaMitra')"
            v-model="$v.data.namaMitra.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Tanggal Gajian" label-cols-md="3">
        <b-form-input
            type="number"
            :state="checkIfValid('tanggalGaji')"
            v-model="$v.data.tanggalGaji.$model"
          ></b-form-input>
      </b-form-group>
      <b-form-group label="Tanggal Tutup Buku" label-cols-md="3">
        <b-form-input
            type="number"
            :state="checkIfValid('tanggalClosing')"
            v-model="$v.data.tanggalClosing.$model"
          ></b-form-input>
      </b-form-group>
      <b-form-group label="BPJS Ketenagakerjaan" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('bebanTenagaKerjaPerusahaan')"
            v-model="$v.data.bebanTenagaKerjaPerusahaan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="BPJS Kesehatan" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('bebanKesehatanPerusahaan')"
            v-model="$v.data.bebanKesehatanPerusahaan.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-mitra')"
        >
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      data: {
        namaMitra: "",
        tanggalGajian: "",
        tanggalClosing: "",
        bebanTenagaKerjaPerusahaan: "",
        bebanKesehatanPerusahaan: ""
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaMitra: {
        required,
      },
      tanggalGaji: {
        required,
      },
      tanggalClosing: {
        required,
      },
      bebanTenagaKerjaPerusahaan: {
        required,
      },
      bebanKesehatanPerusahaan: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "mitra/register", vm.data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MITRA",
                showing: true,
              });
              vm.data.namaMitra = "";
              vm.$bvModal.hide("modal-input-mitra");
              vm.$v.$reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
};
</script>
