<template>
  <div>
    <b-modal
      id="modal-input-koordinator"
      size="lg"
      centered
      title="Tambah Data Koordinator"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Nama Koordinator" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaKoordinator')"
            v-model="$v.data.namaKoordinator.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Kode Koordinator" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('kodeKoordinator')"
            v-model="$v.data.kodeKoordinator.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="$v.data.mitraId.$model"
            :state="checkIfValid('mitraId')"
            :options="mitra"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Provinsi" label-cols-md="3">
          <multiselect
            v-model="$v.data.provinsiId.$model"
            :state="checkIfValid('provinsiId')"
            :options="provinsi"
            @input="getKota(data.provinsiId)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            label="namaProvinsi"
            track-by="id"
            placeholder="-- Pilih Provinsi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kota" label-cols-md="3">
          <multiselect
            v-model="$v.data.kotaId.$model"
            :state="checkIfValid('kotaId')"
            :options="kota"
            @input="getKecamatan(data.kotaId)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKota"
            track-by="idKota"
            placeholder="-- Pilih Kabupaten/Kota --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kecamatan" label-cols-md="3">
          <multiselect
            v-model="$v.data.kecamatanId.$model"
            :state="checkIfValid('kecamatanId')"
            :options="kec"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKecamatan"
            track-by="idKecamatan"
            placeholder="-- Pilih Kecamatan --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('alamatKoordinator')"
            v-model="$v.data.alamatKoordinator.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="No. Telp" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('noHpKantor')"
            v-model="$v.data.noHpKantor.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Latitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('latitude')"
            v-model="$v.data.latitude.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Longtitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('longitude')"
            v-model="$v.data.longitude.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-koordinator')"
        >
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: ["mitra", "provinsi"],
  data() {
    return {
      data: {
        namaKoordinator: "",
        kodeKoordinator: "",
        mitraId: "",
        provinsiId: "",
        kotaId: "",
        kecamatanId: "",
        alamatKoordinator: "",
        noHpKantor: "",
        longitude: 0,
        latitude: 0,
      },
      kota: [],
      kec: [],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      mitraId: {
        required,
      },
      provinsiId: {
        required,
      },
      kotaId: {
        required,
      },
      kecamatanId: {
        required,
      },
      namaKoordinator: {
        required,
      },
      kodeKoordinator: {
        required,
      },
      alamatKoordinator: {
        required,
      },
      noHpKantor: {
        required,
      },
      longitude: {
        required,
      },
      latitude: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getKota(x) {
      let kota = await axios.get(ipBackend + "kota/listByProvinsiId/" + x.id);
      // console.log(kota.data.data);
      this.kota = kota.data.data;
    },
    async getKecamatan(x) {
      let kec = await axios.get(
        ipBackend + "kecamatan/listByKotaId/" + x.idKota
      );
      // console.log(kec.data.data);
      this.kec = kec.data.data;
    },
    simpan() {
      let vm = this;
      vm.data.provinsiId = vm.data.provinsiId.id;
      vm.data.mitraId = vm.data.mitraId.id;
      vm.data.kotaId = vm.data.kotaId.idKota;
      vm.data.kecamatanId = vm.data.kecamatanId.idKecamatan;
      axios
        .post(ipBackend + "koordinator/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN KOORDINATOR",
              showing: true,
            });
            this.data.namaKoordinator = "";
            this.data.kodeKoordinator = "";
            this.data.alamatKoordinator = "";
            this.data.noHpKantor = "";
            this.$v.$reset();
            this.$bvModal.hide("modal-input-koordinator");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
