<template>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark" fluid>
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Mitra</strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-tabs content-class="mt-3" align="center">
                  <b-tab title="Mitra" active>
                    <Mitra />
                  </b-tab>
                  <b-tab title="Koordinator">
                    <Koordinator />
                  </b-tab>
                  <b-tab title="Cabang">
                    <Cabang />
                  </b-tab>
                  <b-tab title="Capem">
                    <Capem />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import Mitra from "@/component/hcms/master/mitraNew/mitra/mitra";
import Koordinator from "@/component/hcms/master/mitraNew/koordinator/koordinator";
import Cabang from "@/component/hcms/master/mitraNew/cabang/cabang";
import Capem from "@/component/hcms/master/mitraNew/capem/capem";
export default {
  components: {
    Mitra,
    Koordinator,
    Cabang,
    Capem,
  },
  name: "mitra",
  data() {
    return {};
  },
};
</script>
